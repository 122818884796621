define('fcs-dashboard/pods/components/models-table-server-paginated/component', ['exports', 'ember', 'ember-models-table/components/models-table-server-paginated', 'ember-models-table/components/models-table'], function (exports, _ember, _emberModelsTableComponentsModelsTableServerPaginated, _emberModelsTableComponentsModelsTable) {
  var _slicedToArray = (function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i['return']) _i['return'](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError('Invalid attempt to destructure non-iterable instance'); } }; })();

  var get = _ember['default'].get;
  var setProperties = _ember['default'].setProperties;
  var computed = _ember['default'].computed;
  var extend = _ember['default'].$.extend;
  var warn = _ember['default'].Logger.warn;
  exports['default'] = _emberModelsTableComponentsModelsTableServerPaginated['default'].extend({

    multipleColumnsSorting: false, // defaults to `false`
    multiSortParameter: 'multiSort',

    observedProperties: ['currentPageNumber', 'sortProperties.[]', 'pageSize'],
    //'filterString', 'processedColumns.@each.filterString'

    willInsertElement: function willInsertElement() {
      var _this = this;

      this._super.apply(this, arguments);
      var observedProperties = get(this, 'observedProperties');
      observedProperties.forEach(function (propertyName) {
        return _this.addObserver(propertyName, _this._addPropertyObserver);
      });
    },

    columnsLastIndex: computed('processedColumns', function () {
      var columns = get(this, 'processedColumns');
      return columns.length - 1;
    }),

    _loadData: function _loadData() {
      var _this2 = this;

      var data = get(this, 'data');
      var currentPageNumber = get(this, 'currentPageNumber');
      var pageSize = get(this, 'pageSize');
      var columns = get(this, 'processedColumns');
      var sortProperties = get(this, 'sortProperties');
      var filterString = get(this, 'filterString');

      if (!get(data, 'query')) {
        warn('You must use http://emberjs.com/api/data/classes/DS.Store.html#method_query for loading data');
        return;
      }
      var query = extend({}, get(data, 'query'));
      var store = get(data, 'store');
      var modelName = get(data, 'type.modelName');

      // Add pagination information
      query[get(this, 'filterQueryParameters.page')] = currentPageNumber;
      query[get(this, 'filterQueryParameters.pageSize')] = pageSize;

      // Add sorting information
      if (get(this, 'multipleColumnsSorting')) {
        console.log(sortProperties); // ['companyName:asc', 'name:desc']
        if (sortProperties && sortProperties.length > 0) {
          query[this.multiSortParameter] = sortProperties.join(','); // 'companyName:asc,name:desc'
        }

        delete query[[get(this, 'filterQueryParameters.sort')]];
        delete query[[get(this, 'filterQueryParameters.sortDirection')]];
      } else {
        var sort = sortProperties && get(sortProperties, 'firstObject');
        if (sort) {
          var _sort$split = sort.split(':');

          var _sort$split2 = _slicedToArray(_sort$split, 2);

          var sortBy = _sort$split2[0];
          var sortDirection = _sort$split2[1];

          query = this.sortingWrapper(query, sortBy, sortDirection.toUpperCase());
        } else {
          delete query[[get(this, 'filterQueryParameters.sort')]];
          delete query[[get(this, 'filterQueryParameters.sortDirection')]];
        }
      }

      // Add global filter
      var globalFilter = get(this, 'filterQueryParameters.globalFilter');
      if (filterString) {
        query[globalFilter] = filterString;
      } else {
        delete query[globalFilter];
      }

      // Add per-column filter
      if (get(this, 'useFilteringByColumns')) {
        columns.forEach(function (column) {
          var filter = get(column, 'filterString');
          var filterTitle = _this2.getCustomFilterTitle(column);
          _this2._setQueryFilter(query, column, filterTitle, filter);
        });
      }

      setProperties(this, { isLoading: true, isError: false });

      var promise = store.query(modelName, query);
      promise.then(function (newData) {
        if (_this2.isDestroyed || _this2.isDestroying) {
          return;
        }
        setProperties(_this2, {
          isLoading: false,
          isError: false,
          filteredContent: newData
        });
      })['catch'](function () {
        if (_this2.isDestroyed || _this2.isDestroying) {
          return;
        }
        setProperties(_this2, {
          isLoading: false,
          isError: true
        });
      });
      return promise;
    },

    actions: {
      filter: function filter() {
        this._loadData();
      },
      sort: function sort(column) {
        console.log('multipleColumnsSorting', get(this, 'multipleColumnsSorting'));
        _emberModelsTableComponentsModelsTable['default'].prototype.actions.sort.call(this, column);
      }
    }
  });
});
define("fcs-dashboard/mirage/fixtures/menus", ["exports"], function (exports) {
  /**
   * Created by alberto on 01/02/16.
   */
  exports["default"] = [{
    "id": 1,
    "name": "Slices Truck",
    "tipEnabled": false,
    "tipDisplayType": 0,
    "tabEnabled": true,
    "serviceFeeEnabled": false,
    "serviceFeeTaxRate": 0,
    "employeeDiscountPercentage": 25.00,
    "taxInclusive": true,
    "customProductEnabled": true,
    "menuTaxRate": 10.00,
    "adminCode": "12345",
    "multiplePagesEnabled": true,
    "cashlessAdminCode": "12345",
    "voidAdminCode": "12345",
    "company": 1,
    "version": 1,
    "currency": "USD",
    "receipt": {
      "enabled": true,
      "address1": "2015 E Riverside Dr",
      "address2": "Austin, TX 78741",
      "footer": "All prices include taxRate",
      "header": "Thank You Please Come Again",
      "logo": "emos.png",
      "defaultReceiptType": 0,
      "printableTenders": [0, 1, 2],
      "merchantCopies": 1,
      "customerCopies": 1,
      "barPrinterCopies": 1,
      "kitchenPrinterCopies": 1,
      "kitchenPrinterAvailable": true
    },
    "menuLocations": [{
      "location": 1,
      "cash": {
        "enabled": true,
        "fastCashEnabled": true
      },
      "manualCredit": {
        "enabled": true,
        "signatureEnabled": true
      },
      "swipeCredit": {
        "enabled": true,
        "signatureEnabled": true
      },
      "chipCredit": {
        "enabled": true,
        "validationMethod": 1
      },
      "cashless": {
        "enabled": true,
        "signatureEnabled": true
      }
    }, {
      "location": 2,
      "cash": {
        "enabled": false,
        "fastCashEnabled": true
      },
      "manualCredit": {
        "enabled": true,
        "signatureEnabled": true
      },
      "swipeCredit": {
        "enabled": true,
        "signatureEnabled": true
      },
      "chipCredit": {
        "enabled": false,
        "validationMethod": 1
      },
      "cashless": {
        "enabled": true,
        "signatureEnabled": false
      }
    }, {
      "location": 3,
      "cash": {
        "enabled": true,
        "fastCashEnabled": true
      },
      "manualCredit": {
        "enabled": true,
        "signatureEnabled": true
      },
      "swipeCredit": {
        "enabled": true,
        "signatureEnabled": true
      },
      "chipCredit": {
        "enabled": true,
        "validationMethod": 0
      },
      "cashless": {
        "enabled": true,
        "signatureEnabled": true
      }
    }],
    "openCashDrawerTenders": [0, 1, 2],
    "transactionSendingType": 1,
    "categories": [1, 2, 3],
    "updatedAt": '2016-05-05T20:15:30.434+0000',
    "menuProductTypes": [{ 'productType': 1, 'taxRate': 2 }, { 'productType': 2, 'taxRate': 2 }, {
      'productType': 3,
      'taxRate': 2
    }]
  }];
});
define("fcs-dashboard/pods/location-group-wristband-attachment/model", ["exports", "ember-data/attr", "model-fragments/fragment", "ember-cp-validations"], function (exports, _emberDataAttr, _modelFragmentsFragment, _emberCpValidations) {

  var Validations = (0, _emberCpValidations.buildValidations)({
    name: (0, _emberCpValidations.validator)('presence', true)
  });

  exports["default"] = _modelFragmentsFragment["default"].extend(Validations, {
    attachmentId: (0, _emberDataAttr["default"])('number'),
    name: (0, _emberDataAttr["default"])('string'),
    attachmentKey: (0, _emberDataAttr["default"])('string'),
    deleted: (0, _emberDataAttr["default"])('boolean')
  });
});
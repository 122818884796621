define("fcs-dashboard/pods/receipt/model", ["exports", "ember", "ember-data/attr", "model-fragments/fragment", "ember-cp-validations"], function (exports, _ember, _emberDataAttr, _modelFragmentsFragment, _emberCpValidations) {

  var Validations = (0, _emberCpValidations.buildValidations)({
    address1: [(0, _emberCpValidations.validator)('presence', { presence: true, descriptionKey: 'menu.builder.steps.stepFive.design.address1.label' }), (0, _emberCpValidations.validator)('length', { min: 1, max: 254, descriptionKey: 'menu.builder.steps.stepFive.design.address1.label' })],
    address2: [(0, _emberCpValidations.validator)('presence', { presence: true, descriptionKey: 'menu.builder.steps.stepFive.design.address2.label' }), (0, _emberCpValidations.validator)('length', { min: 1, max: 254, descriptionKey: 'menu.builder.steps.stepFive.design.address2.label' })],
    header: [(0, _emberCpValidations.validator)('presence', { presence: true, descriptionKey: 'menu.builder.steps.stepFive.design.header.label' }), (0, _emberCpValidations.validator)('length', { min: 1, max: 254, descriptionKey: 'menu.builder.steps.stepFive.design.header.label' })],
    footer: [(0, _emberCpValidations.validator)('presence', { presence: true, descriptionKey: 'menu.builder.steps.stepFive.design.footer.label' }), (0, _emberCpValidations.validator)('length', { min: 1, max: 254, descriptionKey: 'menu.builder.steps.stepFive.design.footer.label' })],
    merchantCopies: [(0, _emberCpValidations.validator)('presence', { presence: true, descriptionKey: 'menu.builder.steps.stepFive.design.merchantCopies.label' }), (0, _emberCpValidations.validator)('number', { gte: '0', descriptionKey: 'menu.builder.steps.stepFive.design.merchantCopies.label' })],
    customerCopies: [(0, _emberCpValidations.validator)('presence', { presence: true, descriptionKey: 'menu.builder.steps.stepFive.design.customerCopies.label' }), (0, _emberCpValidations.validator)('number', { gte: '0', descriptionKey: 'menu.builder.steps.stepFive.design.customerCopies.label' })],
    barPrinterCopies: [(0, _emberCpValidations.validator)('presence', { presence: true, descriptionKey: 'menu.builder.steps.stepFive.design.barPrinterCopies.label' }), (0, _emberCpValidations.validator)('number', { gte: '0', descriptionKey: 'menu.builder.steps.stepFive.design.barPrinterCopies.label' })],
    kitchenPrinterCopies: [(0, _emberCpValidations.validator)('presence', { presence: true, descriptionKey: 'menu.builder.steps.stepFive.design.kitchenPrinterCopies.label' }), (0, _emberCpValidations.validator)('number', { gte: '0', descriptionKey: 'menu.builder.steps.stepFive.design.kitchenPrinterCopies.label' })]
  });

  exports["default"] = _modelFragmentsFragment["default"].extend(Validations, {
    enabled: (0, _emberDataAttr["default"])('boolean'),
    address1: (0, _emberDataAttr["default"])('string'),
    address2: (0, _emberDataAttr["default"])('string'),
    footer: (0, _emberDataAttr["default"])('string'),
    header: (0, _emberDataAttr["default"])('string'),
    logo: (0, _emberDataAttr["default"])('string'),
    defaultReceiptType: (0, _emberDataAttr["default"])('number'),
    printableTenders: (0, _emberDataAttr["default"])(),
    merchantCopies: (0, _emberDataAttr["default"])('number'),
    customerCopies: (0, _emberDataAttr["default"])('number'),
    barPrinterCopies: (0, _emberDataAttr["default"])('number'),
    kitchenPrinterCopies: (0, _emberDataAttr["default"])('number'),
    kitchenPrinterAvailable: (0, _emberDataAttr["default"])('boolean'),
    printableTendersModels: _ember["default"].computed('printableTenders', function () {
      var _this = this;

      var tenders = this.get('printableTenders');
      var tenderModels = [];
      tenders.forEach(function (tenderId) {
        var tender = _this.store.peekRecord('tenderType', tenderId);
        tenderModels.addObject(tender);
      });
      return tenderModels;
    })
  });
});
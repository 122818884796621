define('fcs-dashboard/mirage/factories/menu', ['exports', 'ember-cli-mirage'], function (exports, _emberCliMirage) {
  _emberCliMirage.faker.locale = 'en_US';

  exports['default'] = _emberCliMirage['default'].Factory.extend({
    name: _emberCliMirage.faker.hacker.noun,
    tipEnabled: false,
    tipDisplayType: 0,
    tabEnabled: true,
    serviceFeeEnabled: false,
    serviceFeeTaxRate: _emberCliMirage.faker.random.number(100),
    employeeDiscountPercentage: _emberCliMirage.faker.random.number(100),
    taxInclusive: true,
    customProductEnabled: true,
    menuTaxRate: _emberCliMirage.faker.random.number(100),
    adminCode: _emberCliMirage.faker.hacker.noun,
    cashlessAdminCode: _emberCliMirage.faker.random.number,
    voidAdminCode: _emberCliMirage.faker.random.number,
    company: 1,
    version: 1,
    currency: _emberCliMirage.faker.finance.currencyCode(),
    receipt: {
      enabled: true,
      address1: _emberCliMirage.faker.address.streetAddress("###"),
      address2: _emberCliMirage.faker.address.secondaryAddress(),
      footer: _emberCliMirage.faker.lorem.sentence(),
      header: _emberCliMirage.faker.lorem.sentence(),
      logo: null,
      defaultReceiptType: 0,
      printableTenders: [0, 1, 2],
      merchantCopies: 1,
      customerCopies: 1,
      barPrinterCopies: 1,
      kitchenPrinterCopies: 1,
      kitchenPrinterAvailable: true
    },
    menuLocations: [{
      location: 1,
      cash: {
        enabled: true,
        fastCashEnabled: true
      },
      manualCredit: {
        enabled: true,
        signatureEnabled: true
      },
      swipeCredit: {
        enabled: true,
        signatureEnabled: true
      },
      chipCredit: {
        enabled: true,
        validationMethod: 1
      },
      cashless: {
        enabled: true,
        signatureEnabled: true
      }
    }, {
      location: 2,
      cash: {
        enabled: false,
        fastCashEnabled: true
      },
      manualCredit: {
        enabled: true,
        signatureEnabled: true
      },
      swipeCredit: {
        enabled: true,
        signatureEnabled: true
      },
      chipCredit: {
        enabled: false,
        validationMethod: 1
      },
      cashless: {
        enabled: true,
        signatureEnabled: false
      }
    }, {
      location: 3,
      cash: {
        enabled: true,
        fastCashEnabled: true
      },
      manualCredit: {
        enabled: true,
        signatureEnabled: true
      },
      swipeCredit: {
        enabled: true,
        signatureEnabled: true
      },
      chipCredit: {
        enabled: true,
        validationMethod: 0
      },
      cashless: {
        enabled: true,
        signatureEnabled: true
      }
    }],
    openCashDrawerTenders: [0, 1, 2],
    transactionSendingType: 1,
    categories: [1, 2, 3],
    updatedAt: '2016-05-05T20:15:30.434+0000',
    menuProductTypes: [{ productType: 1, taxRate: _emberCliMirage.faker.random.number(100) }, { productType: 2, taxRate: _emberCliMirage.faker.random.number(100) }, { productType: 3, taxRate: _emberCliMirage.faker.random.number(100) }]
  });
});
/**
 * Created by alberto on 01/02/16.
 */
define("fcs-dashboard/pods/location-group/model", ["exports", "ember", "ember-data/model", "ember-data/attr", "ember-data/relationships", "model-fragments/attributes", "ember-cp-validations"], function (exports, _ember, _emberDataModel, _emberDataAttr, _emberDataRelationships, _modelFragmentsAttributes, _emberCpValidations) {
  var computed = _ember["default"].computed;

  var Validations = (0, _emberCpValidations.buildValidations)({
    name: (0, _emberCpValidations.validator)('presence', { presence: true, descriptionKey: 'locationGroups.details.name.label' }),
    timezone: (0, _emberCpValidations.validator)('presence', {
      presence: true,
      descriptionKey: 'locationGroups.details.timezone.label'
    }),
    locationGroupType: (0, _emberCpValidations.validator)('presence', {
      presence: true,
      descriptionKey: 'locationGroups.details.locationGroupType.label'
    })
  });

  exports["default"] = _emberDataModel["default"].extend(Validations, {
    name: (0, _emberDataAttr["default"])('string'),
    address: (0, _emberDataAttr["default"])('string'),
    logo: (0, _emberDataAttr["default"])('string'),
    cashlessScreenBrand: (0, _emberDataAttr["default"])('string'),
    tipScreenBrand: (0, _emberDataAttr["default"])('string'),
    thankYouScreenBrand: (0, _emberDataAttr["default"])('string'),
    dynamicDescriptor: (0, _emberDataAttr["default"])('string'),
    descriptorEnabled: (0, _emberDataAttr["default"])('boolean'),
    startDate: (0, _emberDataAttr["default"])('date', { defaultValue: null }),
    endDate: (0, _emberDataAttr["default"])('date', { defaultValue: null }),
    timezone: (0, _emberDataAttr["default"])('string'),
    wristbandFiles: (0, _emberDataAttr["default"])('string'),
    isCashless: (0, _emberDataAttr["default"])('boolean', { defaultValue: false }),
    surchargeDisplayNameEnabled: (0, _emberDataAttr["default"])('boolean', { defaultValue: false }),
    surchargeDisplayName: (0, _emberDataAttr["default"])('string', { defaultValue: "Surcharge" }),
    cashDiscountDisplayNameEnabled: (0, _emberDataAttr["default"])('boolean', { defaultValue: false }),
    cashDiscountDisplayName: (0, _emberDataAttr["default"])('string', { defaultValue: "Cash Discount" }),
    userCreationFromTabletEnabled: (0, _emberDataAttr["default"])('boolean', { defaultValue: false }),
    enableBannerBranding: (0, _emberDataAttr["default"])('boolean', { defaultValue: true }),
    isActive: (0, _emberDataAttr["default"])('boolean', { defaultValue: true }),
    digitalTipsDeductionsEnabled: (0, _emberDataAttr["default"])('boolean', { defaultValue: false }),
    locationGroupType: (0, _emberDataRelationships.belongsTo)('locationGroupType'),
    promotions: (0, _emberDataRelationships.hasMany)('promotion'),
    customFees: (0, _emberDataRelationships.hasMany)('customFee'),
    cashlessInformation: (0, _modelFragmentsAttributes.fragment)('cashlessInformation', {
      defaultValue: {
        v4EventId: 0,
        reverseUIDEnabled: false
      }
    }),
    companiesRoles: (0, _modelFragmentsAttributes.fragmentArray)('companyRole', {
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    intl: _ember["default"].inject.service(),
    translatedIsCashless: computed('isCashless', function () {
      if (this.get('isCashless')) {
        return this.get('intl').t('locationGroups.list.cashless.yes');
      } else {
        return this.get('intl').t('locationGroups.list.cashless.no');
      }
    }),
    receiptText: (0, _emberDataAttr["default"])('string'),
    wristbandAttachments: (0, _modelFragmentsAttributes.fragmentArray)('locationGroupWristbandAttachment', {
      defaultValue: function defaultValue() {
        return [];
      }
    })
  });
});